var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createAgentPerformance, deleteAgentPerformance, getAgentPerformanceByUser, getAllAgentPerformances, reloadAllAgentPerformances, updateAgentPerformance, } from './actions';
var initialState = {
    status: IStatus.IDLE,
    reloadStatus: IStatus.IDLE,
    agentPerformances: [],
    agentPerformance: null,
    minMaxOffices: null,
    userAgentPerformance: [],
};
var slice = createSlice({
    name: 'agentPerformance',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            // get all
            .addCase(getAllAgentPerformances.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllAgentPerformances.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.agentPerformances = payload.allPerformances;
            state.minMaxOffices = payload.minMaxOffices;
        })
            .addCase(getAllAgentPerformances.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            // Reload
            .addCase(reloadAllAgentPerformances.pending, function (state) {
            state.reloadStatus = IStatus.LOADING;
        })
            .addCase(reloadAllAgentPerformances.fulfilled, function (state) {
            state.reloadStatus = IStatus.SUCCEEDED;
        })
            .addCase(reloadAllAgentPerformances.rejected, function (state) {
            state.reloadStatus = IStatus.FAILED;
        })
            // get user agent perfs
            .addCase(getAgentPerformanceByUser.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAgentPerformanceByUser.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.minMaxOffices = null;
            state.userAgentPerformance = payload.map(function (ap) { return (__assign({ month: ap.month }, ap.data)); });
        })
            .addCase(getAgentPerformanceByUser.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            //  create
            .addCase(createAgentPerformance.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createAgentPerformance.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.agentPerformance = payload.data;
            state.agentPerformances = state.agentPerformances.map(function (ap) {
                return ap.user._id === payload.data.user._id && ap.date === payload.data.date ? payload.data : ap;
            });
            state.userAgentPerformance = state.userAgentPerformance.map(function (ap) {
                return ap.date === payload.data.date ? __assign(__assign({}, payload.data), { month: ap.month }) : ap;
            });
        })
            .addCase(createAgentPerformance.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            // update
            .addCase(updateAgentPerformance.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateAgentPerformance.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.agentPerformance = payload.data;
            state.agentPerformances = state.agentPerformances.map(function (ap) {
                return ap.user._id === payload.data.user._id && ap.date === payload.data.date ? payload.data : ap;
            });
            state.userAgentPerformance = state.userAgentPerformance.map(function (ap) {
                return ap.date === payload.data.date ? __assign(__assign({}, payload.data), { month: ap.month }) : ap;
            });
        })
            .addCase(updateAgentPerformance.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteAgentPerformance.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteAgentPerformance.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            var agentPerformanceId = payload.agentPerformanceId;
            state.agentPerformances = state.agentPerformances.filter(function (agent) { return agent._id !== agentPerformanceId; });
        })
            .addCase(deleteAgentPerformance.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
